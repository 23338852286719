import Order from './models/Order';
import ArticleOption from './models/ArticleOption';
import Payment from './models/Payment';
import { PaymentStatus } from './models/PaymentStatus';
import OptionGroup from './models/OptionGroup';
import GroupDependency from './models/GroupDependency';
import { OrderStatus } from './enums/OrderStatus';
import Venue from './models/Venue';
import * as moment from 'moment';
import { Moment } from 'moment';
import SlotsConfig from './models/SlotsConfig';
import Slot from './models/Slot';
import Hours from './models/Hours';
import { PreorderType } from './enums/PreorderType';
import { RepositoryService } from './app/services/repository/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from './environments/environment';
import { AxiosError } from 'axios';
import { TranslateService } from '@ngx-translate/core';
import { Api } from './api/api';
import { TimeUtils } from './utils/time-utils';
import { OrderUtils } from './utils/order-utils';
import { OrderType } from './enums/OrderType';
import { TerminalorderType } from './enums/TerminalorderType';
import Offset from './models/Offset';
import Article from './models/Article';
import OptionDefault from './models/OptionDefault';
import { ModalController } from '@ionic/angular';
import { SuccessPage } from './app/pages/payment/success/success.page';
import { Router } from '@angular/router';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ByType } from './models/ByType';
import { ValidationUtils } from './utils/validation-utils';

export class PhoneCountry {
	code: string;
	tel: string;
}

export default class Utils {
	static phoneCountries: PhoneCountry[] = [
		{
			code: 'de',
			tel: '+49',
		},
		{
			code: 'au',
			tel: '+43',
		},
		{
			code: 'fr',
			tel: '+33',
		},
		{
			code: 'lu',
			tel: '+352'
		}
	].filter(pc => environment.countryList.indexOf(pc.code) >= 0);

	static getRequiredArticles(venue: Venue, preorderType: PreorderType): Article[] {
		const requiredTag = preorderType === PreorderType.TAKE_AWAY ? 'take_away_required' : 'delivery_required';
		const requiredArticles: Article[] = [];
		if (venue && venue.articleCategories.length > 0) {
			for (const category of venue.articleCategories) {
				for (const article of category.articles) {
					if (article.tags.find(tag => tag.identifier === requiredTag)) {
						requiredArticles.push(article);
					}
				}
			}
		}
		return requiredArticles;
	}

	static isVenueOpen(venue: Venue): boolean {
		return venue && venue.isServiceActivated && venue.openingHours.length > 0 && TimeUtils.doesHoursMatchNow(venue.openingHours);
	}

	static sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	static venueAcceptsOrders(venue: Venue, preorderType: PreorderType, overridePostalDelivery = false): boolean {
		if (!venue) {
			return false;
		}
		if (!venue.isServiceActivated || !venue.isPublished) {
			return false;
		}
		if (venue.panicEndAt && moment(venue.panicEndAt).isAfter(moment())) {
			return false;
		}
		switch (preorderType) {
			case PreorderType.TAKE_AWAY:
				if (!venue.preorderTakeAway) {
					return false;
				}
				break;
			case PreorderType.DELIVERY:
				if (!venue.deliveryEnabled) {
					return false;
				}
				if (venue.deliveryByRadius && venue.distance !== undefined && venue.distance > venue.deliveryRadius) {
					return false;
				}
				if (venue.deliveryPostalCodes && !venue.deliveryByRadius && !(venue.isPostalDelivery || overridePostalDelivery)) {
					return false;
				}
				break;
		}
		return Utils.getRelevantSchedule(venue, preorderType).length !== 0;
	}

	static isSlotFull(slotConfig: SlotsConfig, slot: Slot, order: Partial<Order>): boolean {
		if (!slot) {
			return false;
		}
		return !this.isValidSlot(slotConfig, slot, order);
	}

	private static isValidSlot(slotConfig: SlotsConfig, slot: Slot, order: Partial<Order>): boolean {
		if (slot.total >= slotConfig.max) {
			return false;
		}
		const other = slot.total - slot.terminal.count;

		const defaultValidation: boolean = Math.max(slot.terminal.count, slotConfig.reserved) + other < slotConfig.max;

		switch (order.type) {
			case OrderType.TERMINAL:
				switch (order.terminalorder.type) {
					case TerminalorderType.INSIDE:
						return slot.terminal.type.inside < slotConfig.terminal.inside;
					case TerminalorderType.TAKE_AWAY:
						return slot.terminal.type.take_away < slotConfig.terminal.takeAway;

					default:
						return true;
				}
			case OrderType.PREORDER:
				if (!defaultValidation) {
					return false;
				}
				switch (order.preorder.type) {
					case PreorderType.DELIVERY:
						return slot.preorder.type.delivery < slotConfig.preorder.delivery;
					case PreorderType.INSIDE:
						return slot.preorder.type.inside < slotConfig.preorder.inside;
					case PreorderType.TAKE_AWAY:
					case PreorderType.PARK_COLLECT:
						return slot.preorder.type.take_away + slot.preorder.type.park_collect < slotConfig.preorder.takeAway;

					default:
						return true;
				}

			default:
				return defaultValidation;
		}
	}

	static paymentFromOrder(order: Order): Payment {
		const payment = new Payment();
		payment.orders = [order._id];
		payment.status = PaymentStatus.created;
		payment.currency = order.currency;
		payment.sum = OrderUtils.orderTotalPrice(order, true, true);
		return payment;
	}

	static numberToCurrency(price: number | { $numberDecimal: number | string }, currencyCode: string): string {
		const safePrice = numberD(price);
		if (!currencyCode) {
			return '' + safePrice;
		}
		return (+safePrice).toLocaleString(navigator.language, {
			style: 'currency',
			currency: currencyCode,
		});
	}

	static async sendOrder(
		router: Router,
		modalCtrl: ModalController,
		translate: TranslateService,
		repository: RepositoryService,
		order: Order,
		loading: (loading) => void,
		snackbarCtrl: MatSnackBar,
		analytics: AngularFireAnalytics
	) {
		order._id = undefined;
		loading(true);
		try {
			order.table = repository._venue.preorderTable;
			order.status = OrderStatus.CREATED;
			await repository.sendOrderAndPay(
				modalCtrl,
				order,
				repository._authLogin ? repository._authLogin.token : null,
				repository._customer,
				analytics
			);
			await SuccessPage.navigate(router, repository._verifiedOrder._id, repository._payment._id);
		} catch (err) {
			if (err !== null) {
				const message = Utils.axiosErrorToMessage(translate, err, order.orderAt);
				console.error(message);
				snackbarCtrl.open(message, null, {
					duration: 5000,
				});
			}
			order.orderAt = null;
			repository.order.emit(order);
		}
		loading(false);
	}

	static array(count: number): number[] {
		const array: number[] = [];
		for (let i = 0; i < count; i++) {
			array.push(i);
		}
		return array;
	}

	static nullOrEmpty(str: string) {
		return str == null || str.length === 0;
	}

	static orderStatusString(translate: TranslateService, orderStatus: OrderStatus): string {
		switch (orderStatus) {
			case OrderStatus.CREATED:
				return translate.instant('order_status.created');
			case OrderStatus.AWAITING_CONFIRMATION:
				return translate.instant('order_status.awaiting_confirmation');
			case OrderStatus.DONE:
				return translate.instant('order_status.done');
			case OrderStatus.READY:
				return translate.instant('order_status.ready');
			case OrderStatus.IN_PREPARATION:
				return translate.instant('order_status.in_preparation');
			case OrderStatus.CANCELLED_BY_CUSTOMER:
				return translate.instant('order_status.canceled_by_customer');
			case OrderStatus.CANCELLED_BY_WAITER:
				return translate.instant('order_status.canceled_by_waiter');
			default:
				return translate.instant('order_status.default');
		}
	}

	static removeFromArray<T>(array: T[], index): T[] {
		const remainingItems = [...array.slice(0, index), ...array.slice(index + 1)];
		while (array.length) {
			array.pop();
		}
		array.push(...remainingItems);
		return array;
	}

	static replaceOptions(
		options: ArticleOption[],
		optionGroup: OptionGroup,
		articleOptions: ArticleOption[],
		times: number,
		dependsOn: string,
		dependency: GroupDependency
	) {
		if (times > 0) {
			articleOptions.map(value => {
				value.dependencyNumber = times;
				value.dependsOn = dependsOn;
				value.dependency = dependency._id;
			});
		}
		options = options.filter(articleOption => articleOption.group !== optionGroup._id);
		options.push(...articleOptions);
	}

	static last(array) {
		if (array.length) {
			return array[array.length - 1];
		} else {
			return array;
		}
	}

	static axiosErrorToMessage(translate: TranslateService, error: AxiosError, time: Moment = null): string {
		if (!error.response || !error.response.data) {
			switch (error.code) {
				case 'ECONNABORTED':
					return translate.instant('axios_errors.econ', { support_email: environment.SUPPORT_EMAIL });
			}
			switch (error.message) {
				case 'Network Error':
					return translate.instant('axios_errors.network');
			}
			return '' + error;
		}
		switch (error.response.status) {
			case 408:
				return translate.instant('axios_errors.408', { support_email: environment.SUPPORT_EMAIL });
			case 500:
				return translate.instant('axios_errors.500');
			case 422:
				switch (error.response.data.code) {
					case -1:
						if (error.response.data.errors && error.response.data.errors.promo) {
							return translate.instant('axios_errors.promo');
						}
						return translate.instant('axios_errors.400_default', {
							name: error.response.data.name,
							status: error.response.status,
							code: error.response.data.code,
							msg: error.response.data.code,
						});
					// panic
					case 2003:
						return translate.instant('axios_errors.2003', { time: moment(time).format('HH:mm') });
					// service inactive
					case 2023:
						return translate.instant('axios_errors.2023', { support_email: environment.SUPPORT_EMAIL });
					// order data is invalid
					case 2004:
						if (error.response.data.errors) {
							if (error.response.data.errors.orderAt || error.response.data.errors.orderedAt) {
								return translate.instant('axios_errors.2004_order', { time: moment(time).format('HH:mm') });
							} else if (error.response.data.errors.phone) {
								return translate.instant('axios_errors.2004_phone');
							} else if (error.response.data.errors.promo) {
								return translate.instant('axios_errors.2004_promo');
							}
						}
						return translate.instant('axios_errors.2004');
				}
				return translate.instant('axios_errors.422', { support_email: environment.SUPPORT_EMAIL });
			case 400:
				switch (error.response.data.code) {
					// error during payment
					case 2512:
					case 2516:
						return translate.instant('axios_errors.2516');
				}
				return translate.instant('axios_errors.400', { support_email: environment.SUPPORT_EMAIL });
			default:
				return translate.instant('axios_errors.400_default', {
					name: error.response.data.name,
					status: error.response.status,
					code: error.response.data.code,
					msg: error.response.data.code,
				});
		}
	}

	static async getSlots(venue: Venue, preorderType: PreorderType, extraSlots = false): Promise<Slot[]> {
		if (!venue || !venue.isServiceActivated || !venue.slot || !venue.slot.active) {
			return [];
		}
		const offset = Utils.getOffset(venue, OrderType.PREORDER, preorderType);
		const now = moment().add(offset.offset, 'minutes').seconds(0).milliseconds(0).add(1, 'minute');
		const schedules = Utils.getRelevantSchedule(venue, preorderType, extraSlots);
		const slots: Slot[] = [];
		for (const schedule of schedules) {
			const hours = TimeUtils.hoursToSchedule(schedule);
			const from = hours.openedAt.add(schedule.connectedWithPrev ? 0 : offset.first, 'minutes');
			const to = hours.closedAt.subtract(offset.last, 'minutes');
			const minFrom = from.isBefore(now) ? now : from;
			if (minFrom.isSameOrAfter(to)) {
				continue;
			}
			try {
				const response = await Api.getSlots(venue._id, minFrom.toISOString(), to.toISOString());
				slots.push(
					...response.data.filter(
						slot =>
							!Utils.isSlotFull(venue.slot, slot, {
								type: OrderType.PREORDER,
								preorder: {
									type: preorderType,
								},
							})
					)
				);
			} catch (e) {}
		}
		return slots;
	}

	static getRelevantSchedule(venue: Venue, preorderType: PreorderType, extraSchedules = false): Hours[] {
		const offset = Utils.getOffset(venue, OrderType.PREORDER, preorderType);
		const now = moment().add(offset.offset, 'minutes').seconds(0).milliseconds(0).add(1, 'minute');
		const relevantSchedule = preorderType === PreorderType.DELIVERY ? venue.deliveryHours : venue.openingHours;
		let schedules = TimeUtils.getSchedulesOfDay(now, relevantSchedule).filter(schedule => {
			try {
				const hour = TimeUtils.hoursToSchedule(schedule);
				const from = hour.openedAt.clone().add(schedule.connectedWithPrev ? 0 : offset.first, 'minutes');
				const to = hour.closedAt.clone().subtract(offset.last, 'minutes');
				const minFrom = from.isBefore(now) ? now : from;
				return minFrom.isBefore(to) || schedule.connectedWithPrev;
			} catch (e) {
				return false;
			}
		});
		if (schedules.length <= 0 || extraSchedules) {
			schedules = [...schedules, ...TimeUtils.getSchedulesOfDay(now.clone().add(1, 'day'), relevantSchedule)];
		}
		if (extraSchedules) {
			schedules = [...schedules, ...TimeUtils.getSchedulesOfDay(now.clone().add(2, 'day'), relevantSchedule)];
		}
		return schedules;
	}

	static firstOrderTime(venue: Venue, type: PreorderType): Moment | null {
		if (!venue) {
			return null;
		}
		const offset = Utils.getOffset(venue, OrderType.PREORDER, type);
		const relevantSchedule = Utils.getRelevantSchedule(venue, type);
		const first = TimeUtils.hoursToSchedule(relevantSchedule.length !== 0 ? relevantSchedule[0] : null);
		return first ? first.openedAt.add(offset.first, 'minutes') : null;
	}

	static getLatLng(venue: Venue): { latitude: number; longitude: number } | null {
		if (!venue || !venue.location || !venue.location.coordinates) {
			return null;
		}
		const latitude = venue.location.coordinates[1];
		const longitude = venue.location.coordinates[0];
		return {
			latitude,
			longitude,
		};
	}

	static getOffset(
		venue: Venue,
		orderType: OrderType,
		preorderType: PreorderType = null,
		terminalOrderType: TerminalorderType = null
	): Offset {
		if (!venue.offsets) {
			if (orderType === OrderType.PREORDER) {
				return {
					first: venue.firstPreorderOffset,
					offset: venue.preorderOffset,
					last: venue.lastPreorderOffset,
				};
			} else {
				return {
					first: 0,
					offset: 0,
					last: 0,
				};
			}
		}
		return this.resolveByType(venue.offsets, orderType, preorderType, terminalOrderType);
	}

	static getAvailability(
		article: Article,
		orderType: OrderType,
		preorderType: PreorderType = null,
		terminalOrderType: TerminalorderType = null
	): boolean {
		if (!article.isActive) {
			return false;
		}
		if (article.hidden) {
			return false;
		}
		if (!article.availability) {
			return article.isActive;
		}
		return this.isAvailable(article.availability, orderType, preorderType, terminalOrderType);
	}

	static resolveByType<T>(
		byType: ByType<T>,
		orderType: OrderType = null,
		preorderType: PreorderType = null,
		terminalOrderType: TerminalorderType = null,
		forceParkCollect: boolean = false
	): T {
		switch (orderType) {
			case OrderType.PREORDER:
				switch (preorderType) {
					case PreorderType.DELIVERY:
						return byType.preorder.delivery;
					case PreorderType.INSIDE:
						return byType.preorder.inside;
					case PreorderType.PARK_COLLECT:
						if (forceParkCollect) {
							return byType.preorder.parkCollect;
						}
						return this.resolveByType(byType, orderType, PreorderType.TAKE_AWAY);
					case PreorderType.TAKE_AWAY:
						return byType.preorder.takeAway;
					default:
						return byType.standard;
				}
			case OrderType.TERMINAL:
				switch (terminalOrderType) {
					case TerminalorderType.INSIDE:
						return byType.terminal.inside;
					case TerminalorderType.TAKE_AWAY:
						return byType.terminal.takeAway;
					default:
						return byType.standard;
				}
			case OrderType.STANDARD:
				return byType.standard;
		}
		return byType.standard;
	}

	static getBasePrice(
		article: Article,
		orderType: OrderType,
		preorderType: PreorderType,
		terminalOrderType: TerminalorderType = null
	): number {
		if (!article.basePriceByType) {
			return +article.basePrice.$numberDecimal;
		}
		return +Utils.resolveByType(article.basePriceByType, orderType, preorderType, terminalOrderType).$numberDecimal;
	}

	static getPrice(
		article: Article,
		orderType: OrderType,
		preorderType: PreorderType,
		terminalOrderType: TerminalorderType = null
	): number {
		if (!article.priceByType) {
			return +article.price.$numberDecimal;
		}
		try {
			const candidate = +this.resolveByType(article.priceByType, orderType, preorderType, terminalOrderType);
			if (candidate && !isNaN(candidate)) {
				return candidate;
			}
		} catch (e) {}
		return +article.price.$numberDecimal;
	}

	static defaultsToArticleOption(
		article: Article,
		selectedOptions: ArticleOption[],
		recommendations: OptionDefault[],
		preorderType: PreorderType
	): ArticleOption[] {
		const result: ArticleOption[] = [];
		result.push(...selectedOptions);
		for (const def of recommendations) {
			const group = article.groups.find(grp => grp._id === def.group);
			const dependency = ValidationUtils.isGroupDependencyFulfilled(article, result, group);
			if (dependency.times < 0) {
				console.log('dependency of group: ' + group.name.de + ' not fulfilled for recommendation');
				continue;
			}
			const defaultOption = new ArticleOption();
			defaultOption.group = def.group;
			defaultOption.quantity = def.quantity;
			defaultOption.article = group.articles.find(art => art._id === def.article);
			if (!defaultOption.article.hidden && Utils.getAvailability(defaultOption.article, OrderType.PREORDER, preorderType)) {
				OrderUtils.addOption(result, defaultOption, group, dependency);
			}
		}
		return result;
	}

	static getCounterArticle(allArticles: Article[], article: Article): Article {
		return article.counterArticle ? allArticles.find(art => art._id === article.counterArticle) : undefined;
	}

	static isActive(allArticles: Article[], article: Article, orderType: OrderType, preorderType: PreorderType): boolean {
		const counter = Utils.getCounterArticle(allArticles, article);
		return (
			!article.hidden &&
			Utils.getAvailability(article, orderType, preorderType) &&
			(!counter || !Utils.isActive(allArticles, counter, orderType, preorderType))
		);
	}

	static isAvailable(
		availability: ByType<boolean>,
		orderType: OrderType = null,
		preorderType: PreorderType = null,
		terminalOrderType: TerminalorderType = null
	): boolean {
		return this.resolveByType(availability, orderType, preorderType, terminalOrderType);
	}

	static phoneCountryComparator(pc1: PhoneCountry, pc2: PhoneCountry): boolean {
		if (pc1 === null && pc2 === null) {
			return true;
		}
		if (pc1 === null || pc2 === null) {
			return false;
		}
		return pc1.code === pc2.code;
	}

	static phoneToPhoneCountryAndPhone(phone: string): { phoneCountry: PhoneCountry; phone: string } {
		if (!phone) {
			return { phone: null, phoneCountry: null };
		}
		for (const pc of Utils.phoneCountries) {
			const phonePrefix = pc.tel.substr(1); // remove +
			if (phone.startsWith(phonePrefix)) {
				return {
					phoneCountry: pc,
					phone: phone.substr(phonePrefix.length), // remove prefix from start
				};
			}
		}
		return { phoneCountry: null, phone };
	}

	static isEmpty(str: string): boolean {
		return !str || str.trim() === '';
	}

	static dateCompare(first: any, second: any): number {
		const firstMoment = moment(first);
		const secondMoment = moment(second);
		if (firstMoment.isBefore(secondMoment)) {
			return 1;
		}
		if (firstMoment.isAfter(secondMoment)) {
			return -1;
		}
		return 0;
	}
}

export const numberComparator = (a: number, b: number) => (a > b ? 1 : a < b ? -1 : 0);
export const numberD = (x: any): number => {
	if (x === undefined) {
		return undefined;
	}
	if (x === null) {
		return null;
	}
	let candidate;
	if (x.$numberDecimal !== undefined) {
		candidate = +x.$numberDecimal;
	} else {
		candidate = +x;
	}
	if (isNaN(candidate)) {
		return null;
	}
	return candidate;
};
export const coverFlow = {
	beforeInit() {
		const swiper = this;

		swiper.classNames.push(`${swiper.params.containerModifierClass}coverflow`);
		swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

		swiper.params.watchSlidesProgress = true;
		swiper.originalParams.watchSlidesProgress = true;
	},
	setTranslate() {
		const swiper = this;
		const { width: swiperWidth, height: swiperHeight, slides, $wrapperEl, slidesSizesGrid, $ } = swiper;
		const params = swiper.params.coverflowEffect;
		const isHorizontal = swiper.isHorizontal();
		const transform$$1 = swiper.translate;
		const center = isHorizontal ? -transform$$1 + swiperWidth / 2 : -transform$$1 + swiperHeight / 2;
		const rotate = isHorizontal ? params.rotate : -params.rotate;
		const translate = params.depth;
		// Each slide offset from center
		for (let i = 0, length = slides.length; i < length; i += 1) {
			const $slideEl = slides.eq(i);
			const slideSize = slidesSizesGrid[i];
			const slideOffset = $slideEl[0].swiperSlideOffset;
			const offsetMultiplier = ((center - slideOffset - slideSize / 2) / slideSize) * params.modifier;

			let rotateY = isHorizontal ? rotate * offsetMultiplier : 0;
			let rotateX = isHorizontal ? 0 : rotate * offsetMultiplier;
			// var rotateZ = 0
			let translateZ = -translate * Math.abs(offsetMultiplier);

			let translateY = isHorizontal ? 0 : params.stretch * offsetMultiplier;
			let translateX = isHorizontal ? params.stretch * offsetMultiplier : 0;

			// Fix for ultra small values
			if (Math.abs(translateX) < 0.001) {
				translateX = 0;
			}
			if (Math.abs(translateY) < 0.001) {
				translateY = 0;
			}
			if (Math.abs(translateZ) < 0.001) {
				translateZ = 0;
			}
			if (Math.abs(rotateY) < 0.001) {
				rotateY = 0;
			}
			if (Math.abs(rotateX) < 0.001) {
				rotateX = 0;
			}

			const slideTransform = `translate3d(${translateX}px,${translateY}px,${translateZ}px)  rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;

			$slideEl.transform(slideTransform);
			$slideEl[0].style.zIndex = -Math.abs(Math.round(offsetMultiplier)) + 1;
			if (params.slideShadows) {
				// Set shadows
				let $shadowBeforeEl = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
				let $shadowAfterEl = isHorizontal
					? $slideEl.find('.swiper-slide-shadow-right')
					: $slideEl.find('.swiper-slide-shadow-bottom');
				if ($shadowBeforeEl.length === 0) {
					$shadowBeforeEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
					$slideEl.append($shadowBeforeEl);
				}
				if ($shadowAfterEl.length === 0) {
					$shadowAfterEl = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
					$slideEl.append($shadowAfterEl);
				}
				if ($shadowBeforeEl.length) {
					$shadowBeforeEl[0].style.opacity = offsetMultiplier > 0 ? offsetMultiplier : 0;
				}
				if ($shadowAfterEl.length) {
					$shadowAfterEl[0].style.opacity = -offsetMultiplier > 0 ? -offsetMultiplier : 0;
				}
			}
		}

		// Set correct perspective for IE10
		if (swiper.support.pointerEvents || swiper.support.prefixedPointerEvents) {
			const ws = $wrapperEl[0].style;
			ws.perspectiveOrigin = `${center}px 50%`;
		}
	},
	setTransition(duration) {
		const swiper = this;
		swiper.slides
			.transition(duration)
			.find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
			.transition(duration);
	},
};

import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import ArticleGroup from '../../../models/ArticleGroup';
import { RepositoryService } from '../../services/repository/repository.service';
import Utils from '../../../utils';
import ArticleOption from '../../../models/ArticleOption';
import OptionGroup from '../../../models/OptionGroup';
import { DisplayMode } from '../../../enums/DisplayMode';
import { MatSnackBar } from '@angular/material/snack-bar';
import FulfilledDependency from '../../../models/FulfilledDependency';
import Article from '../../../models/Article';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';
import RepositoryDirective from '../../pages/repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../utils/order-utils';
import { ValidationUtils } from '../../../utils/validation-utils';
import { RecommendationModalAction, RecommendationModalComponent } from '../recommendation-modal/recommendation-modal.component';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import { PromoCodeType } from '../../../models/PromoCodeType';

@Component({
	selector: 'app-modal-info',
	templateUrl: './modal-info.component.html',
	styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent extends RepositoryDirective implements OnInit, AfterViewInit {
	articleGroup: ArticleGroup;
	// false if coming from checkout
	recommend = true;
	utils = Utils;
	orderUtils = OrderUtils;
	validationUtils = ValidationUtils;
	@ViewChild(IonSlides, { static: true }) slides: IonSlides;
	dm = DisplayMode;
	di = DisplayIdentifier;
	slideOpts = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	sliding = false;
	pages: OptionGroup[][] = [];
	currentPageOverlay = false;
	currentPageIndex = 0;
	indicesOfOverlays: number[] = [];
	indicesOfRecommendations: number[] = [];
	isLastPage = false;

	constructor(
		public modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
		for (const group of this.articleGroup.article.groups) {
			const index = Math.floor(group.sortOrder / 10);
			if (this.pages[index] === undefined) {
				this.pages[index] = [];
			}
			this.pages[index].push(group);
		}
		if (this.pages.length === 0) {
			this.pages.push([]);
		}
		if (this.pages.length > 1) {
			this.pages = this.pages.filter(page => page !== null);
		}
		// if first option group is overlay add empty page at front
		if (
			this.pages.length >= 1 &&
			this.pages[0].length === 1 &&
			(this.pages[0][0].displayMode === DisplayMode.DETAIL_OVERLAY || this.pages[0][0].sortOrder > 9)
		) {
			this.pages = [[], ...this.pages];
		}
		this.indicesOfRecommendations = [];
		this.indicesOfOverlays = [];
		this.pages.forEach((page, pageIndex) => {
			if (page.length > 0 && this.articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)) {
				this.indicesOfRecommendations.push(pageIndex);
			}
			if (page.length > 0 && page[0].displayMode === DisplayMode.DETAIL_OVERLAY) {
				this.indicesOfOverlays.push(pageIndex);
			}
		});
		this.onSlideChange();
	}

	async ngAfterViewInit() {
		await this.slides.update();
	}

	async finish() {
		await this.modalCtrl.dismiss({
			articleGroup: this.articleGroup,
		});
	}

	async close() {
		await this.modalCtrl.dismiss();
	}

	increase() {
		this.articleGroup.quantity += 1;
	}

	decrease() {
		if (this.articleGroup.quantity > 1) {
			this.articleGroup.quantity -= 1;
		}
	}

	async addOverlayOption(pageIndex: number, optionIndex: number, optionArticle: Article, group: OptionGroup) {
		const option = new ArticleOption();
		option.article = optionArticle;
		option.quantity = 1;
		option.group = group._id;
		const fulfilledDependency = new FulfilledDependency();
		fulfilledDependency.dependency = null;
		fulfilledDependency.dependsOn = null;
		fulfilledDependency.times = 0;
		await this.addAndNext(pageIndex, optionIndex, option, group, fulfilledDependency);
	}

	async addAndNext(
		pageIndex: number,
		optionGroupIndex: number,
		option: ArticleOption,
		group: OptionGroup,
		fulfilledDependency: FulfilledDependency
	) {
		await this.add(pageIndex, optionGroupIndex, option, group, fulfilledDependency);
		this.cdr.detectChanges();
		await Utils.sleep(100);
		await this.nextSlide();
	}

	optionGroupDisabled(optionGroup: OptionGroup): boolean {
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.order.promoCode.type === PromoCodeType.BOGO) {
			const bogoArticleIndex = this.order.orderedArticles.findIndex(ag => ag.isPromo);
			const bogoArticle = this.order.orderedArticles[bogoArticleIndex];
			const matchingBogoArticleIndices = this.order.orderedArticles
				.map((ag, agIndex) => {
					return ag.article._id === bogoArticle.article._id &&
						!ag.isPromo &&
						OrderUtils.bogoPrice(ag, this.order.type, this.order.preorder.type) ===
							OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
						? agIndex
						: -1;
				})
				.filter(agIndex => agIndex !== -1);
			// article that should be removed is a possible parent of promoCode article
			if (
				matchingBogoArticleIndices.length === 1 &&
				bogoArticle.article._id === this.articleGroup.article._id &&
				OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type) ===
					OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
			) {
				return !this.recommend;
			}
		}
		return this.articleGroup.isPromo && optionGroup.displayIdentifiers.indexOf(DisplayIdentifier.main) >= 0;
	}

	async add(
		pageIndex: number,
		optionGroupIndex: number,
		option: ArticleOption,
		group: OptionGroup,
		fulfilledDependency: FulfilledDependency
	) {
		if (this.sliding) {
			return;
		}
		OrderUtils.addOption(this.articleGroup.groups, option, group, fulfilledDependency);
		if (
			this.pages[pageIndex].length > optionGroupIndex &&
			group.limit > 0 &&
			this.articleGroup.groups
				.map(addedOption => (group._id === addedOption.group ? addedOption.quantity : 0))
				.reduce((prev, curr) => prev + curr, 0) >= group.limit
		) {
			const slideIndex = await this.slides.getActiveIndex();
			try {
				// @ts-ignore
				const container = this.slides.el.children[0].children[slideIndex].children[0].children[1];
				const options: HTMLCollection = container.children;
				const previousNotFulfilledOptions = this.pages[pageIndex]
					.map(
						(optionGroup, index) =>
							index < optionGroupIndex &&
							ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, this.articleGroup.groups, optionGroup)
								.times < 0
					)
					.filter(res => res).length;
				if (options.length - 1 < optionGroupIndex + 2 - previousNotFulfilledOptions) {
					return;
				}
				options[optionGroupIndex + 2 - previousNotFulfilledOptions].scrollIntoView({ behavior: 'smooth' });
			} catch (e) {
				console.error({
					pageIndex,
					message: 'could not scroll',
					e,
				});
			}
		}
	}

	async pageIndex(): Promise<number> {
		let activeIndex = await this.slides.getActiveIndex();
		activeIndex = activeIndex + this.hiddenCount(activeIndex);
		return activeIndex;
	}

	hiddenCount(until: number): number {
		let realIndex = until;
		this.indicesOfRecommendations.forEach(index => {
			if (realIndex >= index) {
				realIndex = realIndex + 1;
			}
		});
		return this.articleGroup.isRecommendedRecipe
			? this.indicesOfRecommendations
					.map(index => (index <= realIndex ? 1 : 0))
					.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
			: 0;
	}

	async addToOrder() {
		if (
			this.currentPageIndex === 0 &&
			this.recommend &&
			this.articleGroup.article.recommendations &&
			this.articleGroup.article.recommendations.length > 0 &&
			ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0])
		) {
			const recommendations = Utils.defaultsToArticleOption(
				this.articleGroup.article,
				[],
				this.articleGroup.article.recommendations,
				this.order.preorder.type
			);
			const groups: OptionGroup[] = [];
			this.articleGroup.article.recommendations.forEach(option => {
				if (!groups.find(grp => grp._id === option.group)) {
					groups.push(this.articleGroup.article.groups.find(grp => option.group === grp._id));
				}
			});
			// Check if some recommendations are inactive before we allow the user to select recommendations
			const allRequiredAvailable = groups
				.map(grp => recommendations.filter(opt => opt.group === grp._id).length)
				.map((value, index) => groups[index].requiredAmount <= value)
				.reduce((previousValue, currentValue) => previousValue && currentValue, true);
			const recommendationAction = allRequiredAvailable
				? await RecommendationModalComponent.show(this.modalCtrl)
				: RecommendationModalAction.CUSTOMIZATION;
			switch (recommendationAction) {
				case RecommendationModalAction.CUSTOMIZATION:
					this.articleGroup.isRecommendedRecipe = false;
					await Utils.sleep(100);
					if (!(await this.slides.isEnd())) {
						await this.nextSlide();
						return;
					}
					if (!ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups)) {
						this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
							duration: 2000,
						});
						return;
					}
					await this.finish();
					return;
				case RecommendationModalAction.DISMISS:
					return;
				case RecommendationModalAction.RECOMMENDATION:
					this.articleGroup.isRecommendedRecipe = true;
					await Utils.sleep(100);
					// filter options of first page
					const optionsToKeep = [
						...this.articleGroup.groups.filter(
							option => this.pages[0].findIndex(optionGroup => optionGroup._id === option.group) >= 0
						),
						...recommendations,
					];
					this.articleGroup.groups = [];
					optionsToKeep.forEach(opt => {
						const grp = this.articleGroup.article.groups.find(group => group._id === opt.group);
						const dep = ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, optionsToKeep, grp);
						OrderUtils.addOption(this.articleGroup.groups, opt, grp, dep);
					});
					await this.nextSlide();
					return;
				default:
					return;
			}
		} else {
			if (!(await this.slides.isEnd())) {
				await this.nextSlide();
				return;
			}
			if (!ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups)) {
				this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
					duration: 2000,
				});
				return;
			}
			await this.finish();
		}
	}

	async onSlideChange() {
		const pageIndex = await this.pageIndex();
		const slideIndex = await this.slides.getActiveIndex();
		this.currentPageIndex = pageIndex;
		this.currentPageOverlay = this.indicesOfOverlays.indexOf(pageIndex) >= 0;
		this.isLastPage = await this.slides.isEnd();
		console.log({
			slideIndex,
			index: pageIndex,
			currentPageOverlay: this.currentPageOverlay,
			overlays: this.indicesOfOverlays,
			lastPage: this.isLastPage,
			pages: this.pages.map(page => page.map(grp => grp.name.de)),
			modes: this.pages.map(page => page.map(grp => grp.displayMode)),
		});
	}

	async prevSlide() {
		if (this.sliding) {
			return;
		}
		this.sliding = true;
		await this.slides.update();
		if (!(await this.slides.isBeginning())) {
			await this.slides.slidePrev();
		}
		this.sliding = false;
	}

	async nextSlide() {
		if (this.sliding) {
			return;
		}
		this.sliding = true;
		if (await this.slides.isEnd()) {
			await this.addToOrder();
			return;
		}
		await this.validateAndSlideNext(this.pages[await this.pageIndex()]);
	}

	async validateAndSlideNext(optionGroups: OptionGroup[]) {
		const pageValid = ValidationUtils.areGroupsValid(this.articleGroup, optionGroups);
		if (!pageValid) {
			this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
				duration: 2000,
			});
			this.sliding = false;
			return;
		}
		if (await this.slides.isEnd()) {
			await this.addToOrder();
		} else {
			await this.slides.update();
			await this.slides.slideNext();
		}
		this.sliding = false;
	}

	async closeSlide() {
		if (this.sliding) {
			return;
		}
		this.sliding = true;
		await this.slides.update();
		await this.slides.slideTo(0);
		this.sliding = false;
	}

	async openInfo() {
		const modal = await this.modalCtrl.create({
			cssClass: 'short-info-modal auto-height align-bottom-only-mobile',
			component: ShortInfoModalComponent,
			componentProps: {
				desc: this.articleGroup.article.info.de.replace('<a href', '<a target="_blank" href'),
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}

	currentPage(): OptionGroup {
		return this.pages[this.currentPageIndex][0];
	}

	sanitizePageIndex(pageIndex: number): number {
		return pageIndex - this.indicesOfOverlays.map(index => (pageIndex > index ? 1 : 0)).reduce((prev, curr) => prev + curr, 0);
	}

	sanitizePagesLength(pagesLength: number): number {
		return pagesLength - this.indicesOfOverlays.length;
	}

	nextFulfilledPage(currentPage: number): number {
		return this.pages.findIndex(
			(groups, index) => index > currentPage && ValidationUtils.someGroupsFulfilled(this.articleGroup, groups)
		);
	}

	previousFulfilledPage(currentPage: number): number {
		return Math.max(
			...this.pages.map((page, index) => {
				if (index < currentPage && ValidationUtils.someGroupsFulfilled(this.articleGroup, page)) {
					return index;
				} else {
					return 0;
				}
			})
		);
	}

	async clearCurrentPageAndNext(pageIndex) {
		this.articleGroup.groups = this.articleGroup.groups.filter(option => {
			for (const grp of this.pages[pageIndex]) {
				if (option.group === grp._id) {
					return false;
				}
			}
			return true;
		});
		await this.nextSlide();
	}

	async requireOneOfAndNextSlide(pageIndex) {
		const currentPageArticles = this.articleGroup.groups.filter(option => {
			for (const grp of this.pages[pageIndex]) {
				if (option.group === grp._id) {
					return true;
				}
			}
			return false;
		});
		if (currentPageArticles.length === 0) {
			this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
				duration: 2000,
			});
			return;
		}
		await this.nextSlide();
	}

	pageVisible(pageIndex: number): boolean {
		// Some pages needs to be invisible (if dependencies are not fulfilled or recommendations taken)
		return (
			(!this.articleGroup.isRecommendedRecipe || this.indicesOfRecommendations.indexOf(pageIndex) < 0) &&
			ValidationUtils.someGroupsFulfilled(this.articleGroup, this.pages[pageIndex])
		);
	}

	isPageOverlay(pageIndex: number): boolean {
		return pageIndex !== 0 && this.pages[pageIndex][0].displayMode !== DisplayMode.DETAIL_OVERLAY;
	}
}

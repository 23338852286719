import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Customer from '../../../models/Customer';
import { RepositoryService } from '../../services/repository/repository.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Api } from '../../../api/api';
import { HomePage } from '../home/home.page';
import { Gender } from '../../../enums/Gender';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController, PickerController } from '@ionic/angular';
import { SubwayCardModalComponent } from '../../components/subway-card-modal/subway-card-modal.component';
import validator from 'validator';
import RepositoryDirective from '../repository-directive';
import { EmailConfirmationPage } from '../email-confirmation/email-confirmation.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuPage } from '../menu/menu.page.component';
import { ValidationUtils } from '../../../utils/validation-utils';
import Utils from 'src/utils';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-account',
	templateUrl: './account.page.html',
	styleUrls: ['./account.page.scss'],
})
export class AccountPage extends RepositoryDirective {
	static url = 'account';
	customer: Customer;
	loading = false;

	gender = Gender;
	Utils = Utils;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover',
	};
	subcardVerified: boolean = null;

	constructor(
		public repository: RepositoryService,
		private auth: AngularFireAuth,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private pickerCtrl: PickerController,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController
	) {
		super(repository);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(AccountPage.url);
	}

	onUser() {
		super.onUser();
		if (this.user) {
			if (this.user.isAnonymous) {
				HomePage.navigate(this.router);
			} else if (!this.user.emailVerified) {
				EmailConfirmationPage.navigate(this.router, true);
			}
		}
	}

	async saveCustomer() {
		if (!this.user || !this.customer) {
			this.snackbarCtrl.open(this.translate.instant('account.please_wait'), null, {
				duration: 2000,
			});
			return;
		}
		if (!(await this.validate())) {
			return;
		}
		this.loading = true;
		try {
			if (!this.customer._id) {
				this.customer = (await Api.createCustomer(this.customer)).data;
			} else {
				this.customer = (await Api.patchCustomer(this.repository._user.uid, this.customer)).data;
			}
			this.snackbarCtrl.open(this.translate.instant('account.success'), null, {
				duration: 2000,
			});
			this.repository.sanitizeAndEmitCustomer(this.repository._user, this.customer);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('account.error'), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}

	async openCountrySelector() {
		const options = {
			columns: [
				{
					name: 'country',
					class: 'country-picker',
					options: environment.countryList.map(country => {
						return {
							text: this.translate.instant('country.' + country),
							value: country,
						};
					}),
				},
			],
			buttons: [
				{
					text: this.translate.instant('account.country_selection.cancel'),
					role: 'cancel',
				},
				{
					text: this.translate.instant('account.country_selection.save'),
					handler: value => {
						this.customer.country = value.country.value;
					},
				},
			],
		};
		const picker = await this.pickerCtrl.create(options);

		await picker.present();
	}

	async openModal() {
		await SubwayCardModalComponent.openModal(this.modalCtrl);
	}

	async home() {
		await this.repository.reloadCustomer(this.repository._user);
		await HomePage.navigate(this.router);
	}

	ionViewDidEnter() {
		this.validate();
	}

	async validate(): Promise<boolean> {
		this.subcardVerified = null;
		if (!this.customer.name || this.customer.name.length < 3) {
			this.snackbarCtrl.open(this.translate.instant('errors.name'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.customer.email || !validator.isEmail(this.customer.email)) {
			this.snackbarCtrl.open(this.translate.instant('errors.email'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!ValidationUtils.validatePhone(this.customer.phoneCountry, this.customer.phone)) {
			this.snackbarCtrl.open(this.translate.instant('errors.phone'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.customer.street || this.customer.street.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.street'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.customer.number || this.customer.number.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.street_number'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.customer.postalCode || this.customer.postalCode.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.postal'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.customer.city || this.customer.city.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.city'), null, {
				duration: 2000,
			});
			return false;
		}
		if (environment.countryList.indexOf(this.customer.country) < 0) {
			this.snackbarCtrl.open(this.translate.instant('errors.country'), null, {
				duration: 2000,
			});
			return false;
		}
		if (this.customer && this.customer.subcard) {
			this.customer.subcard = this.customer.subcard.replace(/\s/g, '');
		}
		if (this.customer.subcard && this.customer.subcard.length !== 0) {
			if (this.customer.subcard.length !== 16 || !validator.isInt(this.customer.subcard)) {
				this.subcardVerified = false;
				this.snackbarCtrl.open(this.translate.instant('errors.subcard'), null, {
					duration: 2000,
				});
				return false;
			}
			try {
				this.subcardVerified = await Api.isSubCardNumberValid(this.customer.subcard);
			} catch (e) {
				this.subcardVerified = false;
			}
			if (!this.subcardVerified) {
				this.snackbarCtrl.open(this.translate.instant('errors.subcard'), null, {
					duration: 2000,
				});
				return false;
			}
		}
		return true;
	}

	async deleteAccount() {
		const alert = await this.alertCtrl.create({
			header: this.translate.instant('account.delete_account.header'),
			message: this.translate.instant('account.delete_account.msg'),
			buttons: [
				{
					text: this.translate.instant('account.delete_account.no'),
				},
				{
					text: this.translate.instant('account.delete_account.yes'),
					handler: async () => {
						this.loading = true;
						try {
							await Api.deleteAccount(this.user.uid);
							this.snackbarCtrl.open(this.translate.instant('account.delete_account.success'), null, {
								duration: 2000,
							});
							try {
								await this.auth.signOut();
							} catch (logoutError) {
								console.error(logoutError);
							}
							await HomePage.navigate(this.router);
						} catch (e) {
							this.loading = false;
							this.snackbarCtrl.open(this.translate.instant('account.delete_account.error'), null, {
								duration: 2000,
							});
						}
					},
				},
			],
		});
		await alert.present();
		await alert.onDidDismiss();
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}

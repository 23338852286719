const { name, version } = require('../../package.json');
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const subwayCustomerGroup = 'subway_lu';
const customerGroup = subwayCustomerGroup;
export const environment = {
	production: false,
	packageName: name,
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	flavor: 'devDebug',
	placesApiKey: 'AIzaSyBp9hB8RzLkOUbHcWnznjnYHdTQrDUIUow',
	baseUrl: 'https://api.smoothr.de',
	customerAnalyticsId: null,
	customerGTMId: null,
	version,
	SUPPORT_EMAIL: 'subway@smoothr.de',
	firebaseConfig: {
		apiKey: 'AIzaSyAjUNJpF90LNw6Fl6HydWbmOhnJUCq3_5o',
		authDomain: 'subway-fr-auth-dev.firebaseapp.com',
		databaseURL: 'https://subway-fr-auth-dev.firebaseio.com',
		projectId: 'subway-fr-auth-dev',
		storageBucket: 'subway-fr-auth-dev.appspot.com',
		messagingSenderId: '805452883114',
		appId: '1:805452883114:web:f3232fa59a143e47709cd3',
		measurementId: 'G-12LTDBWL1M',
	},
	VR_URL: 'https://vr-pay-ecommerce.de/v1/paymentWidgets.js',
	PAYPAL: {
		BN_CODE: 'SterlixGmbH_MP_Connected',
		JS_URL:
			'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=',
	},
	APAY_MERCHANT_ID: 'merchant.de.smoothr.subway-lu-dev',
	GPAY: {
		GATEWAY: 'aciworldwide',
		MERCHANT_ID: 'BCR2DN6T5OYNT6CG',
	},
	countryList: ['lu'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
